<template>
	<div>
		<add-qcm />
	</div>
</template>
<script>
import AddQcm from '../../../../SuperAdmin/QCM/AddQcm.vue'
export default {
	components: {
		AddQcm
	}
}
</script>
