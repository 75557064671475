<template>
	<section class="add-qcm-container">
		<h1>Créer une question de QCM</h1>

		<form @submit.prevent="submit()" class="form-container">
			<div class="filter-input-container">
				<div class="input-container">
					<label for="niveaux">Niveau</label>
					<select id="niveaux" v-model="form.level" required>
						<option
							v-for="level in this.$store.state.levels"
							:key="level"
							:value="level.id"
						>
							{{ level.name }}
						</option>
					</select>
				</div>
				<div class="input-container">
					<label for="subject">Matière</label>
					<select id="subject" v-model="form.subject" required>
						<option
							v-for="level in this.$store.state.subjects"
							:key="level"
							:value="level.id"
						>
							{{ level.name }}
						</option>
					</select>
				</div>
				<div class="input-container">
					<label for="chapter">Chapitre</label>
					<select v-model="form.chapter" name="chapter" id="chapter">
						<option :value="null">-----</option>
						<optgroup
							v-for="sequence in filteredSequences(this.form.subject)"
							:key="sequence.id"
							:label="sequence.name"
						>
							<option
								v-for="chapter in this.$store.getters.chapters(
									sequence.id
								)"
								:key="chapter.id"
								:value="chapter.id"
							>
								{{ chapter.name }}
							</option>
						</optgroup>
					</select>
				</div>				
				<div class="input-container">
					<label for="theme">Difficulté</label>
					<select id="theme" v-model="form.difficulty" required>
						<option
							v-for="difficulty in this.$store.state.difficulties"
							:key="difficulty"
							:value="difficulty.id"
						>
							{{ difficulty.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="title-question-container">
				<label for=""><h2> Question</h2> </label>
				<LatexEditor :customId="'question'" @update-text="updateLatex" />
			</div>
			<div class="input-file-container" >
				<img width="200" :src="displayedImg" alt="">
				<label for="image">Image de la question</label>
				<input @change="updateImg" id="image" type="file">
			</div>
			

			<div class="answers-container">
				<h2>Réponses</h2>	
				<div v-for="(answer,index) in answers" :key="answer" class="answer-wrapper">
					<input v-model="answer.is_correct" type="checkbox" :name="`answer${index +1}`" :id="`answer${index +1}`">
					<!-- <label :for="`answer${index +1}`">Bonne réponse</label> -->
					<LatexEditor :customId="`answerText${index}`" @update-text="(text) => updateAnswer(text,index)" />
					
					
				</div>
			</div>

			<button class="submit dark">Valider</button>
		</form>
	</section>
	<Teleport to="body">
		<FullPageLoader v-if="isLoading"/>
	</Teleport>
</template>
<script>
import { createQCMQuestionService } from "../../../services/qcmQuestionsServices";
import { createQCMQuestionAnswerService } from "../../../services/qcmQuestionAnswersServices";
import FullPageLoader from "../../Utils/FullPageLoader.vue"
import LatexEditor from "../../Utils/LatexEditor.vue"
export default {
	components: {
		FullPageLoader,
		LatexEditor
	},
	data() {
		return {
			form: {
				level: null,
				chapter: null,
				difficulty: null,
				question: "",
				title: "",
				image: null,
			},
			displayedImg: null,
			answers:[
				
			],
			answerAmount: 4,
			isLoading: false
		}
	},
	methods: {
		updateImg(event) {
			const file = event.target.files[0]
			this.form.image = file
			const reader = new FileReader()
			reader.onload = (e) => {
				this.displayedImg = e.target.result
			}
			reader.readAsDataURL(file)
		},
		updateLatex(latex) {
			this.form.question = latex
		},
		updateAnswer(answer,index) {
			this.answers[index].answer = answer
		},
		isValid() {
			if (this.form.level === null) {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez selectionner un niveau",
				}
				return false
			}
			if (this.form.subject === null) {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez selectionner une matière",
				}
				return false
			}
			
			if (this.form.difficulty === null) {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez selectionner une difficulté",
				}
				return false
			}
			
			if (this.form.question === "") {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez saisir une question",
				}	
				return false
			}
			for (const answer of this.answers) {
				if (answer.answer === "") {
					this.$store.state.toast = {
						status: 'error',
						text: "Veuillez saisir une réponse",
					}
					return false
				}
			}
			if (this.answers.filter(answer => answer.is_correct).length === 0) {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez selectionner une bonne réponse",
				}
				return false
			}
			return true
			
		},
		async submit(){
			if(!this.isValid()) return
			this.isLoading = true

			const formData = new FormData()
			formData.append('question', this.form.question)
			formData.append('level', this.form.level)
			if(this.form.chapter){
				formData.append('chapter', this.form.chapter)
			}
			formData.append('difficulty', this.form.difficulty)
			formData.append('subject', this.form.subject)
			if(this.form.image) {
				formData.append('image', this.form.image)
			}

			const question = await createQCMQuestionService(formData)

			for (const answer of this.answers) {
				const data = {
					...answer,
					question: question.id
				}
				await createQCMQuestionAnswerService(data)
			}
			this.isLoading = false
			this.$store.state.toast = {
				status: 'success',
				text: "Question ajoutée avec succès",
			}
		},
		filteredSequences(subjectId) {
			const sequences = this.$store.getters.sequences(this.form.level)

			if(!sequences) return []
			const filteredSequences = sequences.filter(sequence => sequence.subject === subjectId)
			
			return filteredSequences
			
		}
	},
	created() {
		for (let i = 0; i < this.answerAmount; i++) {
			this.answers.push({answer: "", is_correct: false})
		}
	},
	watch: {
		"form.subject": function() {
			this.form.chapter = null
		},
		"form.level": function() {
			this.form.chapter = null
		}
	}
	
	
	
}
</script>
